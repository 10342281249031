import React, { useState, useEffect } from 'react';

const API_URL = 'https://aanmaster2023.aandemo.com/isapi_xml.php?module=inventory&pageID=741';

const DataFetcher = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(API_URL)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
        setIsLoading(false);
      })
      .catch(error => {
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

return (
  <div>
    <h2>Automobiles</h2>
    <div className="automobiles-list">
      {data.map(car => (
        <div key={car.id} className="automobile-item">
          <img src={car.image_link} alt={car.image_link_alt} className="automobile-image"/>
          <div className="automobile-details">
            <h3>{car.year} {car.make} {car.model} {car.trim}</h3>
            <p>Price: {car.lower_price}</p>
            <p>Mileage: {car.mileage} miles</p>
            <p>Exterior Color: {car.ext_color}</p>
            <p>Interior Color: {car.int_color}</p>
            <p>Body: {car.body}</p>
            <p>VIN: {car.vin}</p>
            <p>Stock Number: {car.stockno}</p>
            <p>Car Fax: <a href={car.cfx} _target="blank">{car.vehicle_name}</a></p>
          </div>
        </div>
      ))}
    </div>
  </div>
);
};

export default DataFetcher;

